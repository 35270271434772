import './App.css';
import l from './lang/lang';
import React, { Component } from 'react';
import DragAndDrop from './components/DragAndDrop';
const zlib = require('zlib')

class App extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
    }
    state = {
        error: null,
        fileName: '',
        state: 'idle',
        decodeStr: '',
        isSelectingFile: false,
        lang: 'en',
        strings: l.strings[0]['en']
    }
    render() {
        const selectFileBound = this.selectFile.bind(this);
        const handleDropBound = this.handleDrop.bind(this);
        const resetAppBound = this.resetApp.bind(this);
        let cta = '';
        let fileName = '';
        if (this.state.fileName) {
            if (this.state.fileName.length > 20) {
                fileName = this.state.fileName.substr(0, 5)
                    + '…'
                    + this.state.fileName.substr(this.state.fileName.length - 12, 12);
            } else {
                fileName = this.state.fileName;
            }
            cta =
                <span className="WIP" onClick={this.downloadFile}>
                    <span>{this.state.state === 'done' ? this.state.strings['downgraded'] : this.state.strings['downgrading']}</span><br />
                    <span>
                        {fileName}
                    </span><br />
                    <span>{this.state.state === 'waiting' ? this.state.strings['pleasewait'] : ''}</span>
                </span>;

        } else {
            cta = this.state.strings['drophere'];
        }

        return (
            <div className="App" >

                <DragAndDrop handleDrop={this.handleDrop} isSelectingFile={this.state.isSelectingFile}>
                    <header onClick={resetAppBound}>
                        <h1>{this.state.strings['adobepp']}</h1>
                        <h2>{this.state.strings['projectdowngrader']}</h2>
                    </header>

                    {
                        (this.state.state === 'idle') &&
                        <div className="dropCircleIdleAnimation"></div>
                    }
                    {
                        (this.state.state === 'idle') &&
                        <div className="dropCircleIdleAnimation2"></div>
                    }
                    {
                        (this.state.state === 'waiting' || this.state.state === 'done') &&
                        <div className="dropCircleWaitingAnimation"></div>
                    }
                    {
                        (this.state.state === 'waiting' || this.state.state === 'done') &&
                        <div className="dropCircleWaitingAnimation2"></div>
                    }
                    <div className="dropCircle" onClick={selectFileBound}>
                        <div className="Pr">Pr</div>
                        <div className="callToAction">
                            {cta}
                        </div>
                    </div>
                    {this.state.error &&
                        <span className="error">{this.state.error}</span>
                    }
                    <footer><a href="https://brunolefevre.net/" title="Bruno Lefèvre Website">{this.state.strings['madeby']} Bruno Lefèvre</a></footer>
                </DragAndDrop>
                <input type="file" id="inputFile" ref={this.fileInputRef} style={{ display: "none" }} onChange={(e) => handleDropBound(e.target.files)} />
            </div>
        );
    }
    componentDidMount() {
        const userLang = navigator.language || navigator.userLanguage;
        let languageCode = userLang.substr(0, 2);

        let authorizedLanguages = [];
        for (let item in l.strings[0]) {
            authorizedLanguages.push(item)
        }

        if (!authorizedLanguages.includes(languageCode)) {
            languageCode = 'en';
        }

        this.setState({ lang: languageCode, strings: l.strings[0][languageCode] })
    }
    resetApp() {
        this.setState({ error: null, fileName: null, state: 'idle' })
        document.getElementById('inputFile').value = null;
    }
    selectFile() {
        if (this.state.state === 'idle') {
            this.setState({ isSelectingFile: true })
            this.fileInputRef.current.click();
        }
    }
    handleDrop = (files) => {

        this.setState({ isSelectingFile: false })
        if (files.length > 1) {
            this.setState({ error: this.state.strings['toomany'] });
            return;
        } else if (files.length < 1) {
            this.setState({ error: this.state.strings['nofile'] });
            return;
        }

        const file = files[0];
        const fileName = file.name;

        const split = fileName.split(".");

        const extension = split[split.length - 1];

        if (extension !== 'prproj') {
            this.setState({ error: this.state.strings['notprproj'] });
            return;
        }

        this.setState({ error: null, state: 'waiting', fileName: fileName });

        const readFileDataBound = this.readFileData.bind(this);

        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function (e) { readFileDataBound(e); }
    }
    readFileData = async (e) => {
        let dataFromFile = e.target.result;
        dataFromFile = dataFromFile.substr(37, dataFromFile.length) // string base 64 string, gzipped, xml file

        this.unzipMe(dataFromFile);
    }


    // Uses the decoded XML to change its version number
    changeVersionNumber = () => {
        const xml = this.state.decodeStr;
        this.setState({ log: xml });

        const splitXml = xml.split("\n");


        let startingChar = 0;
        for (let i = 0; i < 3; i++) {
            startingChar += splitXml[i].length;
        }

        startingChar += 2; // Add line breaks

        const line = splitXml[3];
        startingChar += line.indexOf('Version=');
        startingChar += 10;


        // Replace the version number at specific location in file
        let changedXml = xml.substr(0, startingChar) + '1' + xml.substr(startingChar + 2);
        this.zipMe(changedXml);
    }

    // Gets the zipped XML buffer and download it as a file
    prepareFile = (XML) => {
        const element = document.createElement("a");
        element.setAttribute("id", "file");
        const XMLBlob = new Blob([XML], { type: 'application/gzip;charset=utf-8' });

        const fileName = this.state.fileName;
        const newFileName = fileName.substr(0, fileName.length - 7) + '_downgraded' + fileName.substr(fileName.length - 7, 7)


        element.href = URL.createObjectURL(XMLBlob);
        element.download = newFileName;
        document.body.appendChild(element);
        this.downloadFile();
        this.setState({ state: 'done' });
    }
    downloadFile = () => {
        console.log('dl')
        const element = document.getElementById('file');
        element.click();
    }


    // GZips the XML string, returns buffer 
    zipMe = (xmlString) => {
        const buf = new Buffer(xmlString, 'utf-8');
        // this.downloadFile(xmlString)
        const prepareFileBound = this.prepareFile.bind(this);
        zlib.gzip(buf, function (err, result) {
            if (err) console.error(err);
            prepareFileBound(result) // Returns buffer 
        });
    }

    unzipMe = (base64gz) => {
        // if your data not a base64 then comment this line
        let compressData = atob(base64gz);
        compressData = compressData.split("").map(function (e) {
            return e.charCodeAt(0);
        });

        let binData = new Uint8Array(compressData);

        const utf8ArrayToStrBound = this.utf8ArrayToStr.bind(this);
        const setStateBound = this.setState.bind(this);
        const changeVersionNumberBound = this.changeVersionNumber.bind(this);

        zlib.gunzip(binData, function (err, dezipped) {
            const str = utf8ArrayToStrBound(dezipped);
            setStateBound({ decodeStr: str }, changeVersionNumberBound);

        });
    };
    utf8ArrayToStr = (array) => {
        var charCache = new Array(128); // Preallocate the cache for the common single byte chars
        var charFromCodePt = String.fromCodePoint || String.fromCharCode;
        var result = [];

        var codePt, byte1;
        var buffLen = array.length;

        result.length = 0;

        for (var i = 0; i < buffLen;) {
            byte1 = array[i++];

            if (byte1 <= 0x7f) {
                codePt = byte1;
            } else if (byte1 <= 0xdf) {
                codePt = ((byte1 & 0x1f) << 6) | (array[i++] & 0x3f);
            } else if (byte1 <= 0xef) {
                codePt =
                    ((byte1 & 0x0f) << 12) |
                    ((array[i++] & 0x3f) << 6) |
                    (array[i++] & 0x3f);
            } else if (String.fromCodePoint) {
                codePt =
                    ((byte1 & 0x07) << 18) |
                    ((array[i++] & 0x3f) << 12) |
                    ((array[i++] & 0x3f) << 6) |
                    (array[i++] & 0x3f);
            } else {
                codePt = 63; // Cannot convert four byte code points, so use "?" instead
                i += 3;
            }

            result.push(
                charCache[codePt] || (charCache[codePt] = charFromCodePt(codePt))
            );
        }
        return result.join("");

    }
}



export default App;
