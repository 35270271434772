export default {
    strings: [
        {
            en: {
                downgraded: 'Downgraded!',
                downgrading: 'Downgrading',
                pleasewait: 'Please wait…',
                drophere: 'Drop file here',
                adobepp: 'Adobe Premiere Pro',
                projectdowngrader: 'project downgrader',
                madeby: 'Made by',
                toomany: 'Too many files.',
                nofile: 'Please drop a file.',
                notprproj: 'The file must be a .prproj Premiere Pro Project.',
            },
            fr: {
                downgraded: 'Rétrogradé !',
                downgrading: 'Rétrodragation de',
                pleasewait: 'Veuillez patientez…',
                drophere: 'Déposez le fichier ici',
                adobepp: 'Adobe Premiere Pro',
                projectdowngrader: 'rétrogradeur de projets',
                madeby: 'Réalisé par',
                toomany: 'Trop de fichiers.',
                nofile: 'Veuillez déposer un fichier.',
                notprproj: 'Le fichier doit être un projet Premiere .prproj.',
            },
            eo: {
                downgraded: 'Malpromociita!',
                downgrading: 'Malpromocianta!',
                pleasewait: 'Bonvolu atendi…',
                drophere: 'Metu la dosieron ĉi-tien',
                adobepp: 'Adobe Premiere Pro',
                projectdowngrader: 'projektmalpromociilo',
                madeby: 'Farita de',
                toomany: 'Trop da dosieroj.',
                nofile: 'Bonvelu meti dosieron.',
                notprproj: 'La dosiero devas esti .prproj Premiere Pro projekto.',
            }
        }
    ]

};